import React, { Fragment, useEffect, useState } from "react";
import Sample from "./one_job_modal";
import "./jobs.css";
import ClickSound from "../sound_files/mixkit-plastic-bubble-click-1124.wav";
import useSound from "use-sound";
import Accordion from "react-bootstrap/Accordion";
import "./all_job_for_admin.css";

export default function AllJobs(props) {
  const [jobs, setJobs] = useState({
    jobs: [],
    isLoaded: false,
  });
  const [isFinalLoaded, setIsFinalLoaded] = useState(false);
  const [appliedUsers, setAppliedUsers] = useState({});
  const [error, setError] = useState("");

  const [play] = useSound(ClickSound, { volume: 0.4 });
  // useEffect(() =>{
  //   console.log("useEffect working.")
  //   play()
  // }
  // ,[])

  // style for modal div
  const style = {
    // width: "50%",
    // margin: "0 auto",
    // marginTop: 150,
  };

  // fetch jobs here
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/v1/jobs?month=all`)
      // .then((response) => response.json())
      .then((response) => {
        console.log("Status code is ", response.status);
        if (response.status !== 200) {
          // let err = Error;
          // err.message = "Invalid response code: " + response.status;
          // setValues({
          //   error: err,
          // });
          setError("Invalid response code: " + response.status);
        } else {
          setError(null);
        }
        return response.json();
      })
      .then((json) => {
        setJobs({
          jobs: json.jobs,
          isLoaded: true,
        });
        // looping jobs and set applied users
        console.log("im runnning here 1.", json.jobs);
        // here here yade !! new func put here, arg json.jobs
        getAppliedUsers(json.jobs).then(() => {
          console.log("setting final load");
          setIsFinalLoaded(true);
        });
      });
  }, []); // must set default value

  // for JOB STATUS DISPLAY
  const jobStatus = (job_status) => {
    if (job_status) {
      return "募集中";
    } else {
      return "募集終了";
    }
  };

  async function getAppliedUsers(jobs) {
    // below, props only available at first load. when refresh it is gone, so
    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    if (props.jwts.jwt === "") {
      const t = window.localStorage.getItem("jwts");
      console.log("In props undefined", t);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + JSON.parse(t)["jwt"]);
    } else {
      console.log("In props defined", props);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);
    }

    // ===>>> reqesting
    const requestOptions = {
      method: "GET",
      headers: myHeaders, // added for jwt
    };

    for (let j in jobs) {
      console.log("j is ", j);
      await fetch(
        `${process.env.REACT_APP_API_URL}/v1/admin/get_applied_users/${jobs[j].id}`,
        requestOptions
      )
        .then((response) => {
          if (response.status !== 200) {
            setError((prev) => "Invalid response code: " + response.status);
          }
          return response.json();
        })
        .then((json) => {
          if (json.error) {
            console.error(json.error.message);
          }
          console.log("Sub fetch JSON is ", json);
          return json;
        })
        .then((data) => {
          // do this when setState 2 times, setState multiple time
          setAppliedUsers((prevState) => {
            return { ...prevState, [jobs[j].id]: data };
          });
        })
        .catch((err) => {
          setError((prev) => "Catched error");
        });
    }
  }

  console.log("DEBUG applied user", appliedUsers);

  const showAppliedUsers = (appliedUsers, id) => {
    if (appliedUsers) {
      if (appliedUsers[id].users) {
        let users = appliedUsers[id].users;
        return (
          <>
            <p className="applied-user-table">エントリーしたユーザー:</p>
            <Accordion defaultActiveKey="">
              {users.map((u) => (
                <Accordion.Item eventKey={`${u.user.id}`} key={`${u.user.id}`}>
                  <Accordion.Header>
                    {u.usersub.lastname.String} {u.usersub.firstname.String}{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <table className="table table-triped">
                      <tbody>
                        <tr>
                          <td style={{ whiteSpace: "nowrap" }}>Email</td>
                          <td>{u.user.email}</td>
                        </tr>
                        <tr>
                          <td>名前読み</td>
                          <td>
                            {u.usersub.furi_lastname.String}{" "}
                            {u.usersub.furi_firstname.String}
                          </td>
                        </tr>
                        <tr>
                          <td>性別</td>
                          <td>{u.usersub.sex.String}</td>
                        </tr>
                        <tr>
                          <td>誕生日</td>
                          <td>{u.usersub.birthday.String}</td>
                        </tr>
                        <tr>
                          <td>携帯番号</td>
                          <td>{u.usersub.mobile.String}</td>
                        </tr>
                        <tr>
                          <td>郵便番号</td>
                          <td>{u.usersub.postal.String}</td>
                        </tr>
                        <tr>
                          <td>都道府県</td>
                          <td>{u.usersub.pref.String}</td>
                        </tr>
                        <tr>
                          <td>市町村</td>
                          <td>{u.usersub.city.String}</td>
                        </tr>
                        <tr>
                          <td>住所</td>
                          <td>{u.usersub.address.String}</td>
                        </tr>
                        <tr>
                          <td>最寄り駅</td>
                          <td>{u.usersub.station.String}</td>
                        </tr>
                        <tr>
                          <td>経験</td>
                          <td>{u.usersub.experience.String}</td>
                        </tr>
                        <tr>
                          <td>銀行口座</td>
                          <td>
                            {u.usersub.bank_name.String}{" "}
                            {u.usersub.bank_branch.String}{" "}
                            {u.usersub.bank_acc_type.String}
                          </td>
                        </tr>
                        <tr>
                          <td>銀行口座</td>
                          <td>
                            {u.usersub.bank_acc_name.String}{" "}
                            {u.usersub.bank_acc.String}{" "}
                            {u.usersub.bank_acc_type.String}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </>
        );
      } else {
        return "応募者がいません";
      }
    } else {
      return "no data";
    }
  };

  if (error) {
    return <Fragment>{error}</Fragment>;
  } else {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h3>管理用 お仕事一覧</h3>
            </div>
            {/* <SelectNoLabel
              // title="表示月"
              name="month"
              value={monthToShow.value} // here, if you set fixed value then select not change, need to put state
              placeholder="選択"
              options={[
                { key: `${thisMonth}月のお仕事`, value: thisMonth },
                { key: `${nextMonth}月のお仕事`, value: nextMonth },
              ]}
              col="col-md-3"
              handleChange={handleChangeSelect}
            /> */}
          </div>
        </div>
        <div
          className="mb-3 px-3 py-3 small"
          style={{ backgroundColor: "#FFFFEE" }}
        >
          <p className="mb-0">全てのお仕事が表示されます</p>
          <ol className="mb-0">
            <li>....</li>
          </ol>
        </div>

        {jobs.isLoaded ? (
          <div className="container">
            <div className="row">
              {jobs.jobs.map((m) => (
                <div className="col-md-12" key={m.id}>
                  <div className="card mb-3 col-md-12">
                    <div className="card-header">
                      ID:{m.job_id} {jobStatus(m.status)}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{m.title}</h5>
                      <div className="mb-1">
                        <span className="small">
                          {m.date} {m.start_time.slice(0, 5)}～
                          {m.end_time.slice(0, 5)}
                        </span>
                        {m.badge.split(",").map((bdg, idx) => (
                          <span key={idx} className="small au-badge">
                            {bdg}
                          </span>
                        ))}
                      </div>
                      <p className="card-text mb-1">{m.description}</p>
                      <div style={style} onClick={play}>
                        <Sample job={m} jwts={props.jwts} />
                      </div>
                      <hr />
                      {isFinalLoaded
                        ? showAppliedUsers(appliedUsers, m.id)
                        : "loading data..."}
                    </div>

                    {/* <pre>{JSON.stringify(appliedUsers[m.id], null, 3)}</pre> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Fragment>
    );
  }
}
