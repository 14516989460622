import React, { useEffect, useState, Fragment, Component } from "react";
import { MenuLink } from "./components/menu_link";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Jobs from "./components/jobs";
import EditJob from "./components/edit_job";
import AllJob from "./components/all_job_for_admin";
import Login from "./components/login";
import Signup from "./components/signup";
import MyJobs from "./components/my_jobs";
import AboutUs from "./components/AboutUs";
import Privacy from "./components/Privacy";
import Users from "./components/users";
import "./App.css";

export default function App() {
  // ===>>> start jwt
  const [jwts, setJWT] = useState({
    jwt: "",
    roll: 1,
  });
  // ===>>> keep user
  const [admin, setAdmin] = useState(false);
  // <<<=== keep user

  useEffect(() => {
    let t = window.localStorage.getItem("jwts");
    if (t) {
      if (jwts.jwt === "") {
        setJWT(JSON.parse(t));
      }
    }
  }, []); // i was setting here [jwts].. maybe wrong > same

  function handleJWTChange(jwts) {
    setJWT(jwts);
  }

  function logout() {
    setJWT({ jwt: "", roll: 0 });
    window.localStorage.removeItem("jwts");
  }

  let loginLink;
  if (jwts.jwt === "") {
    loginLink = <Link to="/login">ログイン</Link>;
  } else {
    loginLink = (
      <Link to="/logout" onClick={logout}>
        ログアウト
      </Link>
    );
  }
  // <<<=== end jwt

  // signup link

  let signupLink;
  if (jwts.jwt === "") {
    signupLink = <Link to="/signup/0">新規登録</Link>;
  } else {
    signupLink = jwts.lmail;
  }

  return (
    <Router>
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-2 mt-3 ml-2">
              <img
                src="/allup_logo.png"
                alt="allup_logo"
                width="58"
                height="30"
              />
            </div>
            <div className="col mt-3">
              <h1 className="mt-1 allup top-text">
                allup お仕事マッチングサイト
              </h1>
            </div>
            <div className="col mt-3 text-end">
              {loginLink} {signupLink}
            </div>

            {/* <hr className="mb-3" /> */}
          </div>

          <div className="row mb-3">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                  <a className="navbar-brand" href="#">
                    メニュー
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <MenuLink idx="1" url="/">
                          お仕事
                        </MenuLink>
                      </li>
                      {/* <li className="nav-item">
                        <MenuLink idx="2" url="/category">
                          カテゴリ別
                        </MenuLink>
                      </li> */}
                      {jwts.roll === 0 && (
                        <Fragment>
                          <li className="nav-item">
                            <MenuLink idx="2" url="user/edit-user/e">
                              ユーザー情報編集
                            </MenuLink>
                          </li>
                          <li className="nav-item">
                            <MenuLink idx="3" url="user/myjob">
                              登録済みお仕事リスト
                            </MenuLink>
                          </li>
                        </Fragment>
                      )}
                      {jwts.roll === 9 && (
                        <Fragment>
                          <li className="nav-item">
                            <MenuLink idx="4" url="admin/job/0">
                              お仕事追加
                            </MenuLink>
                          </li>
                          <li className="nav-item">
                            <MenuLink idx="5" url="manage-job">
                              お仕事管理
                            </MenuLink>
                          </li>
                          <li className="nav-item">
                            <MenuLink idx="6" url="manage-user">
                              ユーザー管理
                            </MenuLink>
                          </li>
                        </Fragment>
                      )}
                      <>
                        <li className="nav-item">
                          <MenuLink idx="7" url="about-us">
                            会社概要
                          </MenuLink>
                        </li>
                        <li className="nav-item">
                          <MenuLink idx="8" url="privacy">
                            プライバシーポリシー
                          </MenuLink>
                        </li>
                      </>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          {/* <pre>{JSON.stringify(jwts)}</pre> */}
          <div className="row">
            <div className="col-md-12">
              <Routes>
                <Route path="/" element={<Jobs jwts={jwts} />} />
                <Route path="/user/myjob" element={<MyJobs jwts={jwts} />} />
                <Route
                  path="/admin/job/:id"
                  element={<EditJob jwts={jwts} />}
                />
                <Route path="/manage-job" element={<AllJob jwts={jwts} />} />
                <Route path="/manage-user" element={<Users jwts={jwts} />} />
                <Route
                  path="/login"
                  element={
                    <Login jwts={jwts} handleJWTChange={handleJWTChange} />
                  }
                />
                <Route path="/signup/:id" element={<Signup />} />
                <Route
                  path="/user/edit-user/:id"
                  element={<Signup jwts={jwts} />}
                />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
            </div>
          </div>
        </div>
      </Fragment>
    </Router>
  );
}
