import React from "react";

export default function AboutUs() {
  return (
    <div>
      <div className="p-4">
        <p>■ 会社概要</p>
        <p>
          会社名 株式会社オールアップ
          <br />
          本社所在地　　 〒120-0026 東京都足立区千住旭町11-7
          <br />
          連絡先 TEL：03-6806-1890
          <br />
          事業内容
          セールスプロモーション事業・宅配代行事業・集客コンサルティング
          <br />
          設立 2020年6月9日
          <br />
          代表者 上宇宿 淳<br />
        </p>
      </div>
    </div>
  );
}
