import React from "react";

export default function Privacy() {
  return (
    <div>
      <div className="p-4">
        <p>個人情報の保護について</p>
        <p>
          当サイトを利用する事で、このサイトの閲覧者（以下、利用者）は以下に記載する個人情報保護についての諸事項に同意したものとみなします。これらの諸事項に同意できない場合は、当サイトをご利用になる事ができません。
        </p>
        <p>個人情報の収集</p>
        <p>
          利用者は自身のいかなる情報も開示することなく当サイトを自由に閲覧できるものとします。しかし、場合により利用者の氏名やメールアドレスなどの個人情報の開示をお願いする事があります（例：当サイトの管理者とメールによるやり取りを行う場合など）。利用者から当サイト管理者へ個人情報を送信（開示）された場合、当サイト管理者は利用者の個人情報を利用者の許可なく、第三者へ開示・共有する事はありません。LINEによる友達追加は本人の同意を前提とした友達追加とします。
        </p>
        <p>Cookieの使用</p>
        <p>
          Cookieとは、ウェブサーバ（閲覧しているページの内容を提供しているコンピュータ）から利用者のブラウザへ送信され、利用者の端末内に保存される情報です。Cookieはその情報を送ったウェブサーバによって読まれる可能性があります。Cookieはプログラムではない為、利用者の端末上で単独で実行されることはなく、コンピュータウィルスを内包するものではありません。当サイトでは、アクセス性・利便性を向上させる目的でCookieを使い、当サイトの閲覧状況などの情報を収集する場合があります。このデータは利用者個人を特定・追跡するものではありません。また、当サイトでは第三者広告配信事業者による広告を表示しています。この際にCookieが使用されています。殆どのブラウザは、はじめからCookieが利用されるように設定されていますが、利用者はCookieの受け入れに際して警告を表示したり、拒否をするようにしたり、ブラウザの設定を変更することが可能です。設定を行うには、お使いのブラウザの「ヘルプ」をご確認いただき設定してください。
        </p>
        <p>プライバシー</p>
        <p>
          当サイトのウェブサーバは、利用者のコンピュータのIPアドレスを自動的に収集・記録しますが、これらは利用者個人を特定するものではありません。利用者が自ら個人情報を開示しない限り、利用者は匿名のまま当サイトを閲覧することが可能です。
          当サイトではGoogleの広告サービスやGoogleのアクセス分析ツールを使用しています。当サイトにアクセスすると、お使いのウェブブラウザからGoogleに特定の情報が自動的に送信されます。
        </p>
        <p>免責事項</p>
        <p>
          利用者が当サイトを閲覧し、その内容を参照した事によって何かしらの損害を被った場合でも当サイト管理者はその責任を負いません。また、当サイトからリンクされた、当サイト以外のウェブサイトの内容やサービスに関して、当サイトの個人情報保護についての諸事項は適用されません。
          当サイト以外のウェブサイトの内容及び、個人情報の保護に関しても、当サイト管理者はその責任を負いません。
        </p>
      </div>
    </div>
  );
}
