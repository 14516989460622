const SelectNoLabel = (props) => {
  return (
    <div className={`mb-3 ${props.col}`}>
      {/* <label htmlFor="category_id" className="form-label">
        {props.title}
      </label> */}
      <select
        className="form-select"
        type={props.type}
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
      >
        <option value="">{props.placeholder}</option>
        {props.options.map((option) => {
          return (
            <option
              className="form-select"
              key={option.value}
              value={option.value}
              label={option.key}>{option.key}</option>
          )
        })}
      </select>
    </div>
  );
};

export default SelectNoLabel;

// this is form compnets but select element without label, use for something like filter
// use setState in handleChange to control filter