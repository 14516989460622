import React, { useState, Fragment, useEffect } from "react";
import Input from "./form-components/input";
import Select from "./form-components/select";
import Alert from "./ui-components/alert";
import { useNavigate, useParams } from "react-router-dom";

function Signup(props) {
  // take user id from param (send from link)
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({
    id: `${id}`,
    email: "",
    password: "",
    lastname: "",
    firstname: "",
    furi_lastname: "",
    furi_firstname: "",
    roll: 0,
    birthday: "",
    sex: "",
    mobile: "",
    postal: "",
    pref: "",
    city: "",
    address: "",
    station: "",
    experience: "",
    bank_name: "",
    bank_branch: "",
    bank_acc_type: "",
    bank_acc: "",
    bank_acc_name: "",
  });

  const [error, setError] = useState("");
  const [valerrors, setValErrors] = useState([]);
  const [alertM, setAlertM] = useState({ type: "d-none", message: "" });

  // const navigate = useNavigate();

  // fetch user data if id(url param) is not "0"
  useEffect(() => {
    // ===>>> get user info
    if (id !== "0") {
      // fetch with jwt
      // ===>>> set jwt to send to backend
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

      // ===>>> reqesting
      const requestOptions = {
        method: "GET",
        headers: myHeaders, // added for jwt
      };

      fetch(`${process.env.REACT_APP_API_URL}/v1/user/get_user_info`, requestOptions)
        .then((response) => {
          if (response.status !== 200) {
            setError("Invalid Response:" + response.status);
          } else {
            setError(null);
          }
          return response.json();
        })
        .then((json) => {
          if (json.error) {
            setError("Server says " + json.error.message);
          }
          // setUserInfo(json.userinfo);
          let { user, usersub } = json.userinfo;
          setUserInfo({
            id: user.id,
            email: user.email,
            password: user.password,
            lastname: usersub.lastname.String,
            firstname: usersub.firstname.String,
            furi_lastname: usersub.furi_lastname.String,
            furi_firstname: usersub.furi_firstname.String,
            roll: 0,
            birthday: usersub.birthday.String,
            sex: usersub.sex.String,
            mobile: usersub.mobile.String,
            postal: usersub.postal.String,
            pref: usersub.pref.String,
            city: usersub.city.String,
            address: usersub.address.String,
            station: usersub.station.String,
            experience: usersub.experience.String,
            bank_name: usersub.bank_name.String,
            bank_branch: usersub.bank_branch.String,
            bank_acc_type: usersub.bank_acc_type.String,
            bank_acc: usersub.bank_acc.String,
            bank_acc_name: usersub.bank_acc_name.String,
          });
        });
    }
    // <<<=== end get user info
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    let valerrors = []; // if don't have this one not update when validation error
    if (userInfo.email === "") {
      valerrors.push("email");
    }
    if (userInfo.password.length < 8) {
      valerrors.push("password");
    }
    if (userInfo.firstname === "") {
      valerrors.push("firstname");
    }
    if (userInfo.lastname === "") {
      valerrors.push("lastname");
    }
    if (userInfo.furi_firstname === "") {
      valerrors.push("furi_firstname");
    }
    if (userInfo.furi_lastname === "") {
      valerrors.push("furi_lastname");
    }
    setValErrors(valerrors);

    if (valerrors.length > 0) {
      setAlertM({
        type: "alert-danger",
        message: "フォーム入力に不備があります",
      });
      return false;
    }

    // ===>>> take data from form
    const data = new FormData(evt.target);
    const payload = Object.fromEntries(data.entries());

    // separate for signup and edit
    if (props.jwts && props.jwts.jwt) {

      // fetch with jwt
      // ===>>> set jwt to send to backend
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: myHeaders, // added for jwt
      };

      fetch(`${process.env.REACT_APP_API_URL}/v1/user/edit_user`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // error from server
          if (data.error) {
            setAlertM({
              type: "alert-danger",
              message: data.error.message,
            });
          } else {
            setAlertM({
              type: "alert-success",
              message:
                "正常に更新しました",
            });
          }
        })
        .catch((error) => {
          // error on front
          setAlertM({
            type: "alert-danger",
            message: `Network problem. ${error}`,
          });
        });



      console.log("update")
    } else {
      // ===>>> prepare headers
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // ===>>> reqesting
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
      };

      fetch(`${process.env.REACT_APP_API_URL}/v1/signupworker`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // error from server
          if (data.error) {
            setAlertM({
              type: "alert-danger",
              message: data.error.message,
            });
          } else {
            setAlertM({
              type: "alert-success",
              message:
                "正常にサインアップしました。ログイン画面からログインしてください",
            });
          }
        })
        .catch((error) => {
          // error on front
          setAlertM({
            type: "alert-danger",
            message: `Network problem. ${error}`,
          });
        });
    }
  };

  function hasError(key) {
    return valerrors.indexOf(key) !== -1;
  }

  //handleChange to put inot form element
  const handleChange = (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    setUserInfo({ ...userInfo, [name]: value });
  };

  if (error) {
    return <Fragment>{error}</Fragment>;
  } else {
    return (
      <Fragment>
        <h2>新規ユーザー登録</h2>
        <hr />
        <Alert alertType={alertM.type} alertMessage={alertM.message} />

        <form className="pt-3" onSubmit={handleSubmit}>
          <div className="row">
            <input type="hidden" name="id" id="id" value={userInfo.id} />

            <Input
              title={"姓 [必須]"}
              type={"text"}
              name={"lastname"}
              value={userInfo.lastname}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("lastname") ? "is-invalid" : ""}
              errDiv={hasError("lastname") ? "text-danger" : "d-none"}
              errMsg={"姓を入力してください"}
            />
            <Input
              title={"姓 フリガナ [必須]"}
              type={"text"}
              name={"furi_lastname"}
              value={userInfo.furi_lastname}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("furi_lastname") ? "is-invalid" : ""}
              errDiv={hasError("furi_lastname") ? "text-danger" : "d-none"}
              errMsg={"姓のフリガナを入力してください"}
            />
            <Input
              title={"名 [必須]"}
              type={"text"}
              name={"firstname"}
              value={userInfo.firstname}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("firstname") ? "is-invalid" : ""}
              errDiv={hasError("firstname") ? "text-danger" : "d-none"}
              errMsg={"名前を入力してください"}
            />
            <Input
              title={"名 フリガナ [必須]"}
              type={"text"}
              name={"furi_firstname"}
              value={userInfo.furi_firstname}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("furi_firstname") ? "is-invalid" : ""}
              errDiv={hasError("furi_firstname") ? "text-danger" : "d-none"}
              errMsg={"名前のフリガナを入力してください"}
            />
            <Input
              title={"Eメール [必須]"}
              type={"email"}
              name={"email"}
              value={userInfo.email}
              handleChange={handleChange}
              err={hasError("email") ? "is-invalid" : ""}
              errDiv={hasError("email") ? "text-danger" : "d-none"}
              errMsg={"Please enter a valid email address"}
            />

            <Input
              title={"パスワード（8文字以上） [必須]"}
              type={"text"}
              name={"password"}
              value={userInfo.password}
              handleChange={handleChange}
              err={hasError("password") ? "is-invalid" : ""}
              errDiv={hasError("password") ? "text-danger" : "d-none"}
              errMsg={"8文字以上のパスワードを設定してください"}
            />
            {/* set defalt as this is worker register */}
            <input type="hidden" name="roll" id="roll" value={0} />

            <Input
              title={"生年月日"}
              type={"date"}
              name={"birthday"}
              value={userInfo.birthday}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("birthday") ? "is-invalid" : ""}
              errDiv={hasError("birthday") ? "text-danger" : "d-none"}
              errMsg={"誕生日を入力してください"}
            />

            <Select
              title="性別"
              name="sex"
              value={userInfo.sex}
              placeholder="選択"
              options={[
                { key: "男", value: "男" },
                { key: "女", value: "女" },
              ]}
              col="col-md-6"
              handleChange={handleChange}
            />

            <Input
              title={"携帯番号"}
              type={"text"}
              name={"mobile"}
              value={userInfo.mobile}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("mobile") ? "is-invalid" : ""}
              errDiv={hasError("mobile") ? "text-danger" : "d-none"}
              errMsg={"携帯電話番号を入力してください"}
            />
            <Input
              title={"郵便番号"}
              type={"text"}
              name={"postal"}
              value={userInfo.postal}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("postal") ? "is-invalid" : ""}
              errDiv={hasError("postal") ? "text-danger" : "d-none"}
              errMsg={"郵便番号を入力してください"}
            />
            <Input
              title={"都道府県"}
              type={"text"}
              name={"pref"}
              value={userInfo.pref}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("pref") ? "is-invalid" : ""}
              errDiv={hasError("pref") ? "text-danger" : "d-none"}
              errMsg={"都道府県を入力してください"}
            />
            <Input
              title={"市区町村"}
              type={"text"}
              name={"city"}
              value={userInfo.city}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("city") ? "is-invalid" : ""}
              errDiv={hasError("city") ? "text-danger" : "d-none"}
              errMsg={"市区町村を入力してください"}
            />
            <Input
              title={"住所"}
              type={"text"}
              name={"address"}
              value={userInfo.address}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("address") ? "is-invalid" : ""}
              errDiv={hasError("address") ? "text-danger" : "d-none"}
              errMsg={"住所を入力してください"}
            />
            <Input
              title={"最寄り駅"}
              type={"text"}
              name={"station"}
              value={userInfo.station}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("station") ? "is-invalid" : ""}
              errDiv={hasError("station") ? "text-danger" : "d-none"}
              errMsg={"最寄り駅を入力してください"}
            />

            <Select
              title="サンプリング経験"
              name="experience"
              value={userInfo.experience}
              placeholder="選択"
              options={[
                { key: "有り", value: "有り" },
                { key: "無し", value: "無し" },
              ]}
              col="col-md-6"
              handleChange={handleChange}
            />

            <Input
              title={"銀行名"}
              type={"text"}
              name={"bank_name"}
              value={userInfo.bank_name}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("bank_name") ? "is-invalid" : ""}
              errDiv={hasError("bank_name") ? "text-danger" : "d-none"}
              errMsg={"銀行名を入力してください"}
            />
            <Input
              title={"支店名"}
              type={"text"}
              name={"bank_branch"}
              value={userInfo.bank_branch}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("bank_banch") ? "is-invalid" : ""}
              errDiv={hasError("bank_branch") ? "text-danger" : "d-none"}
              errMsg={"支店名を入力してください"}
            />
            <Input
              title={"口座タイプ"}
              type={"text"}
              name={"bank_acc_type"}
              value={userInfo.bank_acc_type}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("bank_acc_type") ? "is-invalid" : ""}
              errDiv={hasError("bank_acc_type") ? "text-danger" : "d-none"}
              errMsg={"口座タイプを入力してください"}
            />
            <Input
              title={"口座番号"}
              type={"text"}
              name={"bank_acc"}
              value={userInfo.bank_acc}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("bank_acc") ? "is-invalid" : ""}
              errDiv={hasError("bank_acc") ? "text-danger" : "d-none"}
              errMsg={"口座番号を入力してください"}
            />
            <Input
              title={"口座名義人"}
              type={"text"}
              name={"bank_acc_name"}
              value={userInfo.bank_acc_name}
              handleChange={handleChange}
              col="col-md-6"
              err={hasError("bank_acc_name") ? "is-invalid" : ""}
              errDiv={hasError("bank_acc_name") ? "text-danger" : "d-none"}
              errMsg={"口座名義人を入力してください"}
            />
            <hr />
            <button className="btn btn-primary">
              {(props.jwts && props.jwts.jwt) ? "更新" : "登録"}
            </button>
          </div>
          <Alert alertType={alertM.type} alertMessage={alertM.message} />
        </form>
        <hr />
        {/* ===> show form contents */}
        {/* <div className="mt-3">
          <pre>{JSON.stringify(userInfo, null, 3)}</pre>
        </div> */}
      </Fragment>
    );
  }
}
export default Signup;
