import React, { useState, Fragment, useEffect } from "react";
import Input from "./form-components/input";
import Alert from "./ui-components/alert";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [alert, setAlert] = useState({ type: "d-none", message: "" });

  const navigate = useNavigate();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    let errors = []; // if don't have this one not update when validation error
    if (email === "") {
      errors.push("email");
    }
    if (password === "") {
      errors.push("password");
    }
    console.log("debug error", errors)
    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    const data = new FormData(evt.target);
    const payload = Object.fromEntries(data.entries());

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(payload),
    };

    fetch(`${process.env.REACT_APP_API_URL}/v1/signin`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
        }
        return response.json();
      })
      .then((data) => {
          console.log("Log in data: ", data)
        if (data.error) {
          console.log("login failed");
          console.log("error", data.error);
          setAlert({ type: "alert-danger", message: data.error.message });
        } else {
          console.log("Logging in, Object.values(data) is ", Object.values(data));
          handleJWTChange(Object.values(data)[0]);
          window.localStorage.setItem(
            "jwts",
            JSON.stringify(Object.values(data)[0])
          );
          navigate("/");
        }
      });
  };

  function handleJWTChange(jwts) {
    props.handleJWTChange(jwts);
  }

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  // separated for each input but in EditJob, one common handle
  function handlePassword(evt) {
    setPassword(evt.target.value);
  }

  function handleEmail(evt) {
    setEmail(evt.target.value);
  }

  return (
    <Fragment>
      <h2>Login</h2>
      <hr />
      <Alert alertType={alert.type} alertMessage={alert.message} />

      <form className="pt-3" onSubmit={handleSubmit}>
        <Input
          title={"Email"}
          type={"email"}
          name={"email"}
          value={email}
          handleChange={handleEmail}
          err={hasError("email") ? "is-invalid" : ""}
          errDiv={hasError("email") ? "text-danger" : "d-none"}
          errMsg={"Please enter a valid email address"}
        />

        <Input
          title={"Password"}
          type={"password"}
          name={"password"}
          handleChange={handlePassword}
          err={hasError("password") ? "is-invalid" : ""}
          errDiv={hasError("password") ? "text-danger" : "d-none"}
          errMsg={"Please enter a password"}
        />

        <hr />
        <button className="btn btn-primary">Login</button>
      </form>
    </Fragment>
  );
}

export default Login;
