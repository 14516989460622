import React, { useEffect, useState, Fragment, Component } from "react";
import {Link} from 'react-router-dom';
import ClickSound from '../sound_files/mixkit-plastic-bubble-click-1124.wav'
import useSound from "use-sound";


export const MenuLink = (props) => {
  // define state
  const [activeFlags, setActiveFlags] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });


  // testing use effect with sound play when somthing updated
	const [play] = useSound(ClickSound, {volume:0.4})
  useEffect(() =>{
    play()
  }
  ,[activeFlags])

  // toggle class
  const toggleClass = () => {
    Object.keys(activeFlags).map((m) => {
      if (m == props.idx) {
        let currentState = activeFlags[m];
        setActiveFlags({ ...activeFlags, m: !currentState });
      } else {
        if (activeFlags[m]) {

        setActiveFlags({ ...activeFlags, m: false });
        }
      }
    });
  };

  return (
    // <a
    //   className={activeFlags[props.idx] ? "nav-link active" : "nav-link"}
    //   href={props.url}
    //   onClick={toggleClass}
    //   value={props.children}
    // >
    //   {props.children}
    // </a>
    <Link
      className={activeFlags[props.idx] ? "nav-link active" : "nav-link"}
      to={props.url}
      onClick={toggleClass}
      value={props.children}
    >
      {props.children}
    </Link>
  );
};
