// import React from "react";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Alert from "./ui-components/alert";
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // for modal scroll when too long
    maxHeight: "80%",
  },
};

const JobModal = (props) => {
  const [applied, setApplied] = useState(false);
  // for alert as UI
  const [alertM, setAlertM] = useState({
    type: "d-none",
    message: "",
  });

  // modal related
  var subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#1f268c";
  }

  function closeModal() {
    setIsOpen(false);
  }
  // /modal related

  useEffect(() => {
    if (props.jwts.jwt) {

    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

    // ===>>> reqesting
    const requestOptions = {
      method: "GET",
      headers: myHeaders, // added for jwt
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/user/check_user_job/${job.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("DEBUG, data is ",data)
        // error from server
        if (data.error) {
          setAlertM({
            type: "alert-danger",
            message: data.error.message,
          });
        } else {
          if (data.response.message === "1") {
            setApplied(true);
          } else if (data.response.message === "0") setApplied(false);
        }
      })
      .catch((error) => {
        // error on front
        setAlertM({
          type: "alert-danger",
          message: `Network problem. ${error}`,
        });
      });
    } 
  }, [applied]);

  function applyToJob() {
    // use props in JobModal
    console.log(job.id);

    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

    // ===>>> reqesting
    const requestOptions = {
      method: "GET",
      headers: myHeaders, // added for jwt
    };
    fetch(`${process.env.REACT_APP_API_URL}/v1/user/apply_job/${job.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // error from server
        if (data.error) {
          setAlertM({
            type: "alert-danger",
            message: data.error.message,
          });
          // already applied, need other checking api call on useEffect because here wont set on mount
          if (data.error.message === "This record already registered.") {
            setApplied(true);
          }
        } else {
          setAlertM({ type: "alert-success", message: "正常に登録しました" });
          setApplied(true);
        }
      })
      .catch((error) => {
        // error on front
        setAlertM({
          type: "alert-danger",
          message: `Network problem. ${error}`,
        });
      });
  }

  function cancelJob() {

    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

    // ===>>> reqesting
    const requestOptions = {
      method: "GET",
      headers: myHeaders, // added for jwt
    };
    fetch(`${process.env.REACT_APP_API_URL}/v1/user/cancel_job/${job.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // error from server
        if (data.error) {
          setAlertM({
            type: "alert-danger",
            message: data.error.message,
          });
        } else {
          setAlertM({ type: "alert-success", message: "正常に登録解除しました" });
          setApplied(false)
        }
      })
      .catch((error) => {
        // error on front
        setAlertM({
          type: "alert-danger",
          message: `Network problem. ${error}`,
        });
      });
  }

  const { job } = props;

  return (
    <div>
      <Button variant="primary" className="mr-2" onClick={openModal}>
        詳細
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Job Detail Modal"
        // for animation when close
        closeTimeoutMS={150}
        // for warning in console
        ariaHideApp={false}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{job.title}</h2>
        <Button variant="warning" className="mr-2" onClick={closeModal}>
          close
        </Button>
        <table className="table table-triped">
          <tbody>
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>お仕事ID</td>
              <td>{job.job_id}</td>
            </tr>
            <tr>
              <td>場所</td>
              <td>{job.location}</td>
            </tr>
            <tr>
              <td>日付</td>
              <td>{job.date}</td>
            </tr>
            <tr>
              <td>集合時間</td>
              <td>{job.meet_time}</td>
            </tr>
            <tr>
              <td>開始時間</td>
              <td>{job.start_time}</td>
            </tr>
            <tr>
              <td>終了時間</td>
              <td>{job.end_time}</td>
            </tr>
            <tr>
              <td>休憩時間</td>
              <td>{job.break_time} 分</td>
            </tr>
            <tr>
              <td>内容</td>
              <td>{job.description}</td>
            </tr>
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>ステータス</td>
              <td>{job.status ? "募集中" : "募集終了"}</td>
            </tr>
            <tr>
              <td>備考</td>
              <td>{job.requirement}</td>
            </tr>
            <tr>
              <td>人数</td>
              <td>{job.n_people}</td>
            </tr>
            <tr>
              <td>時給</td>
              <td>{job.wage} 円</td>
            </tr>
          </tbody>
        </table>
        {(props.jwts.jwt && props.jwts.roll === 0) ? (applied? <a href="#" className="btn btn-danger" onClick={cancelJob}>
            登録済みエントリー解除
          </a>: 
          <a href="#" className="btn btn-primary" onClick={applyToJob}>
            エントリー
          </a> 
        ): (<a href="#" className="btn btn-secondary">
          エントリーするにはログインしてください
        </a>)}
        <Alert alertType={alertM.type} alertMessage={alertM.message} />
      </Modal>
      {props.jwts.roll === 9 && (
        <Link to={`/admin/job/${job.id}`} className="btn btn-warning ms-1">
          編集
        </Link>
      )}
    </div>
  );
};

export default JobModal;
