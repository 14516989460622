import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./edit_job.css";
import Input from "./form-components/input";
import Textarea from "./form-components/textarea";
import Select from "./form-components/select";
import Alert from "./ui-components/alert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function EditJob(props) {
  const [job, setJob] = useState({
    title: "",
    description: "",
    date: "",
    category_id: "",
    badge: "",
    capacity: "",
    status: "",
    location: "",
    start_time: "",
    end_time: "",
    meet_time: "",
    break_time: "",
    working_time: "",
    n_people: "",
    job_id: "",
    requirement: "",
    wage: "",
  });
  const [error, setError] = useState("");
  // for form validation
  const [errors, setErrors] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([
    { key: "opt1", value: 1 },
    { key: "opt2", value: 2 },
  ]);
  // for alert as UI
  const [alertM, setAlertM] = useState({
    type: "d-none",
    message: "",
  });
  // for page redirect after deleting job
  const navigate = useNavigate();

  // take url param
  const { id } = useParams();

  // fetch job here
  useEffect(() => {
    if (props.jwts.jwt === "") {
      navigate("/login")
    }
    // fetch
    if (id > 0) {
      fetch(`${process.env.REACT_APP_API_URL}/v1/job/` + id)
        .then((response) => {
          if (response.status !== 200) {
            setError("Invalid Response:", response.status);
          } else {
            setError(null);
          }
          return response.json();
        })
        .then((json) => {
          setJob(json.job);
        });
    }

    // ====>>>> manually set a job
    // setJob({
    //     title: "test title title",
    //     date: "2022-02-02",
    //     category_id: "0",
    //     description: "desc",
    //     badge: "badge",
    //     capacity: "1",
    //     status: "1",
    //     location: "basho",
    //     start_time: "04:38",
    //     end_time: "04:39",
    //     meet_time: "03:37",
    //     break_time: "30",
    //     working_time: "90",
    //     n_people: "more than 2",
    //     job_id: "job id",
    //     requirement: "hahahah",
    //     wage: "900",
    // });

    // ====> fetch all job example
    //   fetch("http://${process.env.REACT_APP_API_URL}/v1/jobs")
    //     .then((response) => {
    //       console.log("Status code is ", response.status);
    //       if (response.status != "200") {
    //         setError("Invalid response code: " + response.status);
    //       } else {
    //         setError(null);
    //       }
    //       return response.json();
    //     })
    //     .then((json) => {
    //       setJob({
    //         job:json.job,
    //         isLoaded: true
    //       });
    //     });
  }, []); // must set default value

  // updatintg form value handlers, buind this onChange,
  // with this version, had ot set like handleChange("category_id")
  const handleChange = () => (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    setJob({
      job: { ...job, [name]: value },
    });
  };

  // testing version2
  const handleChange1 = (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    setJob({ ...job, [name]: value });
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    // ===>>> start validation
    let errors = [];
    if (job.title === "") {
      errors.push("title");
    }
    setErrors(errors);
    if (errors.length > 0) {
      return false;
    }
    // <<<=== end validation
    // ===>>> take data from form
    const data = new FormData(evt.target);
    const payload = Object.fromEntries(data.entries());
    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

    // ===>>> reqesting
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: myHeaders, // added for jwt
    };
    fetch(`${process.env.REACT_APP_API_URL}/v1/admin/editjob`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // error from server
        if (data.error) {
          setAlertM({
            type: "alert-danger",
            message: data.error.message,
          });
        } else {
          setAlertM({ type: "alert-success", message: "正常に保存しました" });
        }
      })
      .catch(error => { 
        // error on front
          setAlertM({
            type:"alert-danger",
            message: `Network problem. ${error}`
          })
        
      })
  };

  const confirmDelete = (e) => {
    confirmAlert({
      title: "コンテンツの削除",
      message: "本当に削除しますか？",
      buttons: [
        {
          label: "はい",
          onClick: () => {
            // ===>>> set jwt to send to backend 
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);

            // ===>>> reqesting
            const requestOptions = {
              method: "GET",
              headers: myHeaders, // added for jwt
            };
            fetch(`${process.env.REACT_APP_API_URL}/v1/admin/deletejob/` + job.id, requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  setAlertM({
                    type: "alert-danger",
                    message: data.error.message,
                  });
                } else {
                  // as SPA, use this to redirect
                  navigate("/admin")
                }
              });
          },
        },
        {
          label: "いいえ",
          onClick: () => {},
        },
      ],
    });
  };

  if (error) {
    return <Fragment>{error}</Fragment>;
  } else {
    return (
      <Fragment>
        { (id > 0)?<h3>お仕事編集</h3> :<h3>お仕事新規登録</h3>}
        
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="row">
            <input type="hidden" name="id" id="id" value={id} />

            <Input
              title="タイトル"
              name="title"
              type="text"
              value={job.title}
              col=""
              err={hasError("title") ? "is-invalid" : ""}
              errDiv={hasError("title") ? "text-danger" : "d-none"}
              errMsg={"タイトルを記入してください"}
              handleChange={handleChange1}
            />

            <Textarea
              title="詳細"
              name="description"
              value={job.description}
              handleChange={handleChange1}
            />

            <Select
              title="カテゴリー"
              name="category_id"
              value={job.categoryid}
              placeholder="選択"
              options={categoryOptions}
              col=""
              handleChange={handleChange1}
            />

            <Input
              title="バッジ （複数可）"
              name="badge"
              value={job.badge}
              handleChange={handleChange1}
              col="col-md-6"
              type="text"
              placeholder="例）ティッシュ,簡単,食事付"
            />

            <Input
              title="定員"
              name="capacity"
              value={job.capacity}
              col="col-md-6"
              type="number"
              handleChange={handleChange1}
            />

            <Select
              title="募集状態"
              name="status"
              value={job.status}
              placeholder="選択"
              options={[
                { key: "募集中", value: 1 },
                { key: "停止中", value: 0 },
              ]}
              col="col-md-6"
              handleChange={handleChange1}
            />

            <Input
              title="場所"
              name="location"
              value={job.location}
              col="col-md-6"
              type="text"
              handleChange={handleChange1}
            />

            <Input
              title="日付"
              name="date"
              value={job.date}
              col="col-md-3"
              type="date"
              handleChange={handleChange1}
            />

            <Input
              title="開始時間"
              name="start_time"
              value={job.start_time}
              col="col-md-3"
              type="time"
              handleChange={handleChange1}
            />

            <Input
              title="終了時間"
              name="end_time"
              value={job.end_time}
              col="col-md-3"
              type="time"
              handleChange={handleChange1}
            />

            <Input
              title="集合時間"
              name="meet_time"
              value={job.meet_time}
              col="col-md-3"
              type="time"
              handleChange={handleChange1}
            />

            <Input
              title="休憩時間（分）"
              name="break_time"
              value={job.break_time}
              col="col-md-3"
              type="number"
              handleChange={handleChange1}
            />

            <Input
              title="実労働時間（分）"
              name="working_time"
              value={job.working_time}
              col="col-md-3"
              type="number"
              handleChange={handleChange1}
            />

            <Input
              title="チーム人数（例：2人以上）"
              name="n_people"
              value={job.n_people}
              col="col-md-3"
              type="text"
              handleChange={handleChange1}
            />

            <Input
              title="お仕事ID"
              name="job_id"
              value={job.job_id}
              col="col-md-3"
              type="text"
              handleChange={handleChange1}
            />

            <Textarea
              title="備考（募集要項）"
              name="requirement"
              value={job.requirement}
              handleChange={handleChange1}
            />

            <Input
              title="時給 (円)"
              name="wage"
              value={job.wage}
              type="number"
              handleChange={handleChange1}
            />
          </div>
          <hr />
          <Alert alertType={alertM.type} alertMessage={alertM.message} />
          <button className="btn btn-primary">保存</button>
          <Link to="/" className="btn btn-warning ms-1">
            一覧に戻る
          </Link>
          {id > 0 && (
            // <a href="#!" onClick={() => confirmDelete()}
            <a
              href="#!"
              onClick={confirmDelete}
              className="btn btn-danger ms-1"
            >
              削除
            </a>
          )}
        </form>
        {/* ===> show form contents */}
        {/* <div className="mt-3">
          <pre>{JSON.stringify(job, null, 3)}</pre>
        </div> */}
      </Fragment>
    );
  }
}
