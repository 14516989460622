import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";

export default function Users(props) {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  function getAllUsers() {
    // below, props only available at first load. when refresh it is gone, so
    // ===>>> set jwt to send to backend
    const myHeaders = new Headers();
    if (props.jwts.jwt === "") {
      const t = window.localStorage.getItem("jwts");
      console.log("In props undefined", t);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + JSON.parse(t)["jwt"]);
    } else {
      console.log("In props defined", props);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);
    }

    // ===>>> reqesting
    const requestOptions = {
      method: "GET",
      headers: myHeaders, // added for jwt
    };

    fetch(`${process.env.REACT_APP_API_URL}/v1/admin/allusers`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          setError((prev) => "Invalid response code: " + response.status);
        }
        return response.json();
      })
      .then((json) => {
        if (json.error) {
          console.error(json.error.message);
        }
        console.log("Sub fetch JSON is ", json);
        return json;
      })
      .then((data) => {
        setUsers(data.users);
        setLoaded(true);
      })
      .catch((err) => {
        setError((prev) => "Catched error");
      });
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  if (loaded) {
    return (
      <>
        <div>登録済みユーザーリスト</div>

        <Accordion defaultActiveKey="">
          {users.map((u) => (
            <Accordion.Item eventKey={`${u.user.id}`} key={`${u.user.id}`}>
              <Accordion.Header>
                {u.usersub.lastname.String} {u.usersub.firstname.String}{" "}
              </Accordion.Header>
              <Accordion.Body>
                <table className="table table-triped">
                  <tbody>
                    <tr>
                      <td style={{ whiteSpace: "nowrap" }}>Email</td>
                      <td>{u.user.email}</td>
                    </tr>
                    <tr>
                      <td>名前読み</td>
                      <td>
                        {u.usersub.furi_lastname.String}{" "}
                        {u.usersub.furi_firstname.String}
                      </td>
                    </tr>
                    <tr>
                      <td>性別</td>
                      <td>{u.usersub.sex.String}</td>
                    </tr>
                    <tr>
                      <td>誕生日</td>
                      <td>{u.usersub.birthday.String}</td>
                    </tr>
                    <tr>
                      <td>携帯番号</td>
                      <td>{u.usersub.mobile.String}</td>
                    </tr>
                    <tr>
                      <td>郵便番号</td>
                      <td>{u.usersub.postal.String}</td>
                    </tr>
                    <tr>
                      <td>都道府県</td>
                      <td>{u.usersub.pref.String}</td>
                    </tr>
                    <tr>
                      <td>市町村</td>
                      <td>{u.usersub.city.String}</td>
                    </tr>
                    <tr>
                      <td>住所</td>
                      <td>{u.usersub.address.String}</td>
                    </tr>
                    <tr>
                      <td>最寄り駅</td>
                      <td>{u.usersub.station.String}</td>
                    </tr>
                    <tr>
                      <td>経験</td>
                      <td>{u.usersub.experience.String}</td>
                    </tr>
                    <tr>
                      <td>銀行口座</td>
                      <td>
                        {u.usersub.bank_name.String}{" "}
                        {u.usersub.bank_branch.String}{" "}
                        {u.usersub.bank_acc_type.String}
                      </td>
                    </tr>
                    <tr>
                      <td>銀行口座</td>
                      <td>
                        {u.usersub.bank_acc_name.String}{" "}
                        {u.usersub.bank_acc.String}{" "}
                        {u.usersub.bank_acc_type.String}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
}
