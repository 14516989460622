import React, { Fragment, useEffect, useState } from "react";
import JobModal from "./one_job_modal";
import "./jobs.css";
import ClickSound from "../sound_files/mixkit-plastic-bubble-click-1124.wav";
import useSound from "use-sound";
import SelectNoLabel from "./form-components/select-no-label";

export default function Jobs(props) {
  // ===>>> start set month and get from select
  let d = new Date();
  const thisMonth = d.getMonth() + 1;
  let nextMonth = d.getMonth() + 2;
  if (nextMonth === 13) {
    nextMonth = 1;
  }

  const [monthToShow, setMonthToShow] = useState({
    value: thisMonth,
    testValue: 0,
  });

  const handleChangeSelect = (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    setMonthToShow({ ...monthToShow, ["value"]: value });
    console.log("on change...", name, monthToShow);
  };

  console.log("on top...", monthToShow);
  // <<<=== end set month

  const [jobs, setJobs] = useState({
    jobs: [],
    isLoaded: false,
  });
  const [error, setError] = useState("");

  const [play] = useSound(ClickSound, { volume: 0.4 });
  // useEffect(() =>{
  //   play()
  // }
  // ,[])

  // style for modal div
  const style = {
    // width: "50%",
    // margin: "0 auto",
    // marginTop: 150,
  };

  // fetch jobs here
  useEffect(() => {
    // do something here
    fetch(`${process.env.REACT_APP_API_URL}/v1/jobs?month=${monthToShow.value}`)
      // .then((response) => response.json())
      .then((response) => {
        console.log("Status code is ", response.status);
        if (response.status !== 200) {
          // let err = Error;
          // err.message = "Invalid response code: " + response.status;
          // setValues({
          //   error: err,
          // });
          setError("Invalid response code: " + response.status);
        } else {
          setError(null);
        }
        return response.json();
      })
      .then((json) => {
        setJobs({
          jobs: json.jobs,
          isLoaded: true,
        });
      })
      .catch((error) => {
        // error on front
        setError(
          `ネットワークに問題があります。インターネット接続の確認後も改善しない場合はしばらく後にお試しください。 ${error}`
        );
      });
  }, [monthToShow]); // must set default value

  // for JOB STATUS DISPLAY
  const jobStatus = (job_status) => {
    if (job_status) {
      return "募集中";
    } else {
      return "募集終了";
    }
  };

  // function runs at unmounted moment.
  // useEffect(()=>{return () =>{
  //   console.log("unmounted.")
  // }} )

  if (error) {
    return <Fragment>{error}</Fragment>;
  } else {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h3>お仕事一覧</h3>
            </div>
            <SelectNoLabel
              // title="表示月"
              name="month"
              value={monthToShow.value} // here, if you set fixed value then select not change, need to put state
              placeholder="選択"
              options={[
                { key: `${thisMonth}月のお仕事`, value: thisMonth },
                { key: `${nextMonth}月のお仕事`, value: nextMonth },
              ]}
              col="col-md-3"
              handleChange={handleChangeSelect}
            />
          </div>
        </div>
        <div
          className="mb-3 px-3 py-3 small"
          style={{ backgroundColor: "#FFFFEE" }}
        >
          <p className="mb-0">サイトの使い方</p>
          <ul className="mb-0">
            <li>
              「allup
              お仕事マッチングサイト」は、あなたのスキマ時間に好きなお仕事ができる、お仕事マッチングサイトです！
            </li>
            <li>
              画面右上の新規登録ボタンから、無料会員登録をおねがいします。
              右上のログインボタンから登録したパスワードでログインしてください。
            </li>
          </ul>
          <p className="mb-0 mt-1">エントリー方法</p>
          <ol className="mb-0">
            <li>詳細ボタンをタップしてお仕事内容を確認する</li>
            <li>エントリーボタンでお仕事にエントリーします</li>
          </ol>
          <p className="mb-0 mt-1">
            ※エントリーには無料会員登録が必要です。まだの方は画面右上からどうぞ
          </p>
        </div>

        {jobs.isLoaded ? (
          <div className="container">
            <div className="row">
              {jobs.jobs
                ? jobs.jobs.map((m) => (
                    <div className="col-md-6" key={m.id}>
                      <div className="card mb-3 col-md-12">
                        <div className="card-header">
                          ID:{m.job_id} {jobStatus(m.status)}
                        </div>
                        <div className="card-body">
                          <div>
                            <h5 className="card-title">{m.title}</h5>
                          </div>
                          <div className="mb-1">
                            <span className="small">
                              {m.date
                                .replace("-", "年")
                                .replace("-", "月")
                                .replace("-", "日")}{" "}
                              {m.start_time.slice(0, 5)}～
                              {m.end_time.slice(0, 5)}
                            </span>
                            {m.badge.split(",").map((bdg, idx) => (
                              <span key={idx} className="small au-badge">
                                {bdg}
                              </span>
                            ))}
                          </div>
                          <p className="card-text mb-0">{m.description}</p>
                          <p className="card-info mb-1">時給{m.wage}円</p>
                          <div style={style} onClick={play}>
                            <JobModal job={m} jwts={props.jwts} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : "お仕事情報はまだ登録されていません"}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Fragment>
    );
  }
}
